<template>
  <v-list>
    <v-list-item
      v-for="(item, index) in items"
      :key="index"
      v-on="selectable ? { click: () => handleItemClick(item) } : {}"
    >
      <v-list-item-avatar>
        <v-icon dark> mdi-desktop-classic </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{ item[attributeToShow] }}</v-list-item-title>
        <v-list-item-subtitle> <slot name="subtitle"> </slot> </v-list-item-subtitle>

      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon :class="{ 'not-selectable': !optionsSelectable }">
                <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
              dense
                v-for="(option, optionIndex) in menuOptions"
                :key="optionIndex"
                @click="handleOptionClick(item, option)"
              >
                <v-list-item-icon v-if="option.icon">
                  <v-icon>{{ option.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ option.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "GenericList",
  props: {
    items: {
      type: Array,
      required: true,
    },
    attributeToShow: {
      type: String,
      required: true,
    },

    menuOptions: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: true,
    },
    optionsSelectable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    handleItemClick(item) {
      if (this.selectable) {
        // Lógica que se ejecuta al hacer clic en un ítem
        this.$emit("item-selected", item);
      }
    },
    handleOptionClick(item, option) {
      // Lógica que se ejecuta al hacer clic en una opción del menú
      this.$emit("option-selected", item, option);
    },
  },
};
</script>
<style scoped>
.v-list-item--link:before {
  background-color: transparent !important;
}
</style>