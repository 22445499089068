<template>
  <LayoutBasic :showLinksMenu="false">
    <template v-slot:navigation-title> Marcas </template>
    <template v-slot:navigation-content>
      <v-list-item>
        <v-list-item-title>
          <v-text-field
            rounded
            outlined
            style="background-color: #3a3b3c"
            dense
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar marca"
            single-line
            hide-details
            autofocus
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:navigation-before-links>
      <v-list>
        <v-list-item @click="dialogNuevaMarca = true">
          <v-list-item-avatar>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Nueva marca</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-dialog v-model="dialogUpdate" max-width="500px">
        <marca-update
          v-if="dialogUpdate"
          :marca="marcaUpdate"
          @cerrar-dialog="dialogUpdate = false"
        ></marca-update>
      </v-dialog>
      <marca-create
        v-if="dialogNuevaMarca"
        @close-dialog="dialogNuevaMarca = false"
      ></marca-create>
    </template>
    <template v-slot:vista-previa-title> Lista de marcas </template>
    <template v-slot:vista-previa-action> </template>
    <template v-slot:vista-previa-list>
      <lista-marcas :search="search"></lista-marcas>
      
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import ListaMarcas from "@/components/marcas/marca.list";
import MarcaCreate from "@/components/marcas/marca.create";
import MarcaUpdate from "@/components/marcas/marca.update";
import { mapGetters } from "vuex";

export default {
  name: "Marcas",
  components: {
    ListaMarcas,
    LayoutBasic,
    MarcaCreate,
    MarcaUpdate,
  },
  data() {
    return {
      search: "",
      dialogNuevaMarca: false,
      activeBtn: 0,
      componentes: ["ListaMarcas"],
      menuOptions: [{ icon: null, title: "Editar" }],
      marcaUpdate: null,
      dialogUpdate: false,
    };
  },

  computed: {
    ...mapGetters("marcas", ["marcas"]),

    items() {
      return [
        {
          text: "Nueva marca",
          icon: "mdi-clock",
          route: "promocionNueva",
        },
      ];
    },
  },
  methods: {

  },
};
</script>

<style scoped></style>
