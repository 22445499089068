<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <form-marca
        titulo="Nueva Marca"
        :dataMarca="dataMarca"
        @processMarca="registrar"
        @cerrar-dialog="$emit('close-dialog')"
      ></form-marca>
    </v-dialog>
  </div>
</template>

<script>
import FormMarca from "@/components/marcas/FormMarca";
import { mapActions } from "vuex";
export default {
  name: "MarcaCreate",
  components: {
    FormMarca,
  },
  data() {
    return {
      dialog: true,
      dataMarca: {
        nombre: "",
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions("marcas", ["registrar_marca"]),

    async registrar(data) {
      this.registrar_marca(data)
        .then(() => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
 button:false,
            color: "success",
            text: `Se registró la marca '${data.nombre}'`,
            icon: "mdi-hand-okay",
            timeout: 2500,
          });
          this.$emit("close-dialog");
          // this.dataMarca.nombre = "";
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
 button:false,
            color: "primary",
            icon: "mdi-information",

            text: `${e.response.data.nombre}`,
            timeout: 3000,
            right: false,
            top: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
