<template>
  <div>
    <v-toolbar color="">
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <v-col cols="12">
          <v-text-field
            autofocus
            v-model="dataMarca.nombre"
            id="nombre"
            label-for="nombre"
            label="Nombre"
            v-validate="'required|max:60'"
            :counter="60"
            :error-messages="errors.collect('nombre')"
            data-vv-name="nombre"
            @keyup.enter="validateForm()"
            required
          ></v-text-field>
          <!-- <VTextFieldWithValidation
              outlined
              label="Nombre"
              autofocus
              v-model="dataMarca.nombre"
              rules="required|max:60"
              :counter="60"
              name="Nombre"
            /> -->
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="lime accent-3" @click="registrar">Guardar</v-btn>-->
        <v-btn text color="grey" @click="$emit('cerrar-dialog')">Cancelar</v-btn>
        <v-btn color="success" @click="validateForm()" :disabled="errors.any()">{{
          textButton
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "FormMarca",
  $_veeValidate: {
    validator: "new",
  },

  props: {
    dataMarca: {
      type: Object,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
      default: "Marcas",
    },
    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },
  },

  computed: {
    ...mapState("marcas", ["refresh"]),
  },

  methods: {
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("processMarca", this.dataMarca);
        } else {
          this.$validator.validate();
        }
      });
    },
  },
  mounted() {},
  watch: {
    // Se activa cuando se ejecuta la mutacion add_marca
    refresh: function (val) {
      if (val) {
        this.$validator.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
