<template>
  <div>
    <form-marca
      titulo="Editar marca"
      :dataMarca="marcaCopy"
      textButton="Guardar"
      @processMarca="actualizar"
      @cerrar-dialog="$emit('cerrar-dialog')"
    ></form-marca>
  </div>
</template>

<script>
import FormMarca from "@/components/marcas/FormMarca";
import { mapActions } from "vuex";

export default {
  name: "MarcaUpdate",
  components: {
    FormMarca,
  },
  props: {
    marca: {
      type: Object,
      required: true,
    },
  },
  computed: {
    marcaCopy() {
      return { ...this.marca };
    },
  },
  methods: {
    ...mapActions("marcas", ["actualizar_marca"]),
    actualizar(data) {
      this.actualizar_marca(data)
        .then(() => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Se actualizó la marca`,
            icon: "mdi-hand-okay",
            timeout: 2500,
          });
          this.$emit("cerrar-dialog");
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "primary",
            icon: "mdi-information",

            text: `${e.response.data.nombre}`,
            timeout: 3000,
            right: false,
            top: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
