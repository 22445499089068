<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <!-- <GenericList
        :menuOptions="menuOptions"
        :items="marcas"
        :selectable="false"
        :optionsSelectable="true"
        attributeToShow="nombre"
        @option-selected="handleOptionSelected"
      ></GenericList> -->
        <v-data-table
          :headers="headers"
          :items="marcas"
          class="elevation-1"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Lista de marcas</v-toolbar-title>

              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editarMarca(item)">mdi-pencil</v-icon>
            <v-icon small @click="processEliminarMarca(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay marcas registradas.</v-alert
            >
          </template>
        </v-data-table>
        <v-dialog v-model="dialogUpdate" max-width="500px">
          <marca-update
            v-if="dialogUpdate"
            :marca="marcaUpdate"
            @cerrar-dialog="dialogUpdate = false"
          ></marca-update>
        </v-dialog>
        <layout-dialog-delete
          @close-dialog="dialogDelete = false"
          @processDelete="eliminarMarca"
          v-if="dialogDelete"
        >
          <template v-slot:title> Eliminar marca </template>
          <template v-slot:message>
            ¿Desea eliminar la marca '<i>{{ marcaParaEliminar.nombre }}</i> ' ?
          </template>
        </layout-dialog-delete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

import MarcaUpdate from "@/components/marcas/marca.update";
import MarcaCreate from "@/components/marcas/marca.create";
import GenericList from "@/components/util/GenericList.vue";


export default {
  name: "ListaMarcas",
  components: {
    MarcaUpdate,
    MarcaCreate,
    LayoutDialogDelete,
    GenericList
  },
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
       menuOptions: [{ icon: null, title: "Editar" }],
      dialogUpdate: false,
      marca_update: {},
      dialogDelete: false,
      marcaParaEliminar: null,

      headers: [
        { text: "Nombre", value: "nombre", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],

      loading: false,
    };
  },
  computed: {
    ...mapGetters("marcas", ["marcas"]),
  },
  methods: {
    ...mapActions("marcas", ["fetch_marcas","eliminar_marca"]),
        handleOptionSelected(item, option) {
      console.log("option:", option);
      switch (option.title) {
        case "Editar":
          this.editarMarca()
          break;

        default:
          break;
      }
      return;
    },

    processEliminarMarca(marca) {
      this.marcaParaEliminar = marca;
      this.dialogDelete = true;
    },
    eliminarMarca() {
      this.eliminar_marca(this.marcaParaEliminar.id)
        .then((data) => {
          this.dialogDelete = false;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `Se eliminó la marca '${this.marcaParaEliminar.nombre}'`,
            icon: "mdi-hand-okay",
            timeout: 2500,
          });
        })
        .catch((e) => {
          if (e.response.status === 409) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "primary",
              icon: "mdi-information",

              text: `${e.response.data.data}'`,
              timeout: 5000,
              right: false,
              top: false,
            });
            this.dialogDelete = false;
          }
        });
    },
    editarMarca(prop) {
      let marca = { ...prop };
      this.marcaUpdate = marca;
      this.dialogUpdate = true;
    },
    fetchMarcas() {
      this.fetch_marcas()
    }
  },
  created(){
    this.fetchMarcas()
  }
};
</script>

<style lang="scss" scoped></style>
