<template>
  <v-row>
    <v-col cols="12">
      <v-dialog persistent v-model="dialogEliminar" width="500">
       
        <v-card color="#242526">
          <v-card-title>
             <div style="font-size: 1.25rem; font-weight: 700;">
              <slot name="title"></slot>
            </div>
            <v-spacer>
            </v-spacer>
              <v-btn @click="$emit('close-dialog')" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div style="font-size: .9375rem; margin-top: 5px">
              <slot name="message"></slot>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('close-dialog')"  text color="grey">Cancelar</v-btn>
            <v-btn @click="processDelete" :loading="loading" color="red lighten-1">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LayoutDialogDelete",
  data() {
    return {
      dialogEliminar: true,
      loading: false
    };
  },
  methods: {
    processDelete() {
    this.loading = true

      this.$emit('processDelete')
    }

  },
};
</script>

<style  scoped>
</style>